.Footer_footer__ruqkr {
  margin-top: calc(7.5rem * var(--mantine-scale));
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  background-color: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__ruqkr {
  background-color: var(--mantine-color-dark-6);
}
  .Footer_footer__ruqkr {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-2);
}
  [data-mantine-color-scheme='dark'] .Footer_footer__ruqkr {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-5);
}

/* max-width: rem(200px); */

@media (max-width: 48em) {

.Footer_logo__HVFjb {
    display: flex;
    flex-direction: column;
    align-items: center
}
  }

.Footer_description___O_c_ {
  margin-top: calc(0.3125rem * var(--mantine-scale));
}

@media (max-width: 48em) {

.Footer_description___O_c_ {
    margin-top: var(--mantine-spacing-xs);
    text-align: center
}
  }

.Footer_inner__KctKP {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {

.Footer_inner__KctKP {
    flex-direction: column;
    align-items: center
}
  }

.Footer_groups__BJimh {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 48em) {

.Footer_groups__BJimh {
    display: none
}
  }

.Footer_wrapper__kOo5w {
  width: calc(10rem * var(--mantine-scale));
}

.Footer_link__C1_KE {
  display: block;
  color: var(--mantine-color-gray-6);
}

[data-mantine-color-scheme='dark'] .Footer_link__C1_KE {
  color: var(--mantine-color-dark-1);
}

.Footer_link__C1_KE {
  font-size: var(--mantine-font-size-sm);
  padding-top: calc(0.1875rem * var(--mantine-scale));
  padding-bottom: calc(0.1875rem * var(--mantine-scale));
}

.Footer_link__C1_KE:hover {
    text-decoration: underline;
  }

.Footer_title__5_9de {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Footer_title__5_9de {
  color: var(--mantine-color-white);
}

.Footer_afterFooter__XJ5kA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-2);
}

[data-mantine-color-scheme='dark'] .Footer_afterFooter__XJ5kA {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-4);
}

@media (max-width: 48em) {

.Footer_afterFooter__XJ5kA {
    flex-direction: column
}
  }

@media (max-width: 48em) {

.Footer_social__9sPuG {
    margin-top: var(--mantine-spacing-xs)
}
  }

